export const questionsReponses = [

    {
        id:'5',
        name: 'vocabularium',
        color:'text-emerald-400',
        qr:[
            {
                question:"A quel besoin répond cette application de bureau ? Quelles sont ses fonctionnalités ?",
                reponse: `
                Bien souvent quand on lit on ne prend pas le temps de s’arrêter sur les mots qu’on ne connait pas (par manque de temps ou de commodité), et on se dit que le contexte de la phrase et/ou de l’histoire est suffisant pour se passer d’une définition exacte. <br/>
                Toutefois, prendre ce temps permet d’enrichir son vocabulaire, de mieux s’exprimer à l’écrit comme à l’oral. Par ailleurs, bien souvent, pour fournir un effort constant, notamment un effort de mémorisation, il faut <span class="font-black">"hacker" le fonctionnement de son cerveau pour l’inciter à l’effort </span>en mettant en place un mécanisme effort/récompense. <br/>
                Partant de ce constat je me suis dis qu’il serait bien de développer un outil ludique qui me permettrait de mémoriser les mots nouveaux rencontrés au fil de mes lectures et de les mémoriser sans avoir à le faire dans un document Word austère et dans une disposition d’esprit rigide. <br/> 
                Ici la récompense c’est l’obtention d’un titre de niveau supérieur, l’obtention d’une gemme (qu’on obtient en jouant au dojo des mots à partir de 7 bonnes réponses). On peut suivre l’avancement de son apprentissage avec des indicateurs clés qui permettent de savoir quels sont les mots qui restent à apprendre, ceux sur lesquels ont a échoué à donner une bonne réponse, ceux qui n’ont pas encore été joués.
                <br/>Les fonctionnalités de cette application sont les suivantes : rechercher un mot (l’application utilise <span class="font-black">l’API du dictionnaire Larousse</span> pour récupérer la définition du mot recherché) et l’enregistrer, s’entraîner avec les mots capturés, mettre à l’épreuve son apprentissage dans le dojo des mots (un jeu où il faut retrouver le mot à partir de sa définition en un temps limité), suivre l’avancement de l’apprentissage des mots (nombre de fois consécutive où un même mot a été correctement restitué, nombre de coffre élite débloqués sur les 10 dernières parties etc.)
                <br/> Le but ultime est de collecter toutes les pierres précieuses (certaines comme le diamant ayant une probabilité d’apparition de 1/10000) et d’atteindre le dernier niveau (15000 mots !)
                `,
                colorHexa:'#34d399',
            },
            {
                question: "Comment tu gères l’affichage des éléments cliquable dans les différents étages de la maison dans l’arbre ?",
                reponse:`
                Chaque étage est une image de fond qui comprend des éléments cliquables qui lui sont propres. <br/>
                L’apparence des éléments allumés/éteints est obtenu avec un petit hack (une petite astuce) : l’élément cliquable a 2 états (allumé et éteint) qui sont liés à des 3 évènements : le survol de la souris dans la zone cliquable ou <span class="font-black">hover</span>, l’éloignement de la souris ou <span class="font-black">leave</span> et le <span class="font-black">click</span>. <br/>
                L’évènement hover permet d’afficher l’élément cliquable allumé (lorsque la souris survole l’élément) tandis que l’évènement leave affiche l’élément cliquable éteint. <br/>
                Ensuite, il s’agit de gérer l’affichage et la disparition des éléments (un peu comme en JS quand on fait un display :none sur un élément du DOM) selon l’étage sur lequel l’utilisateur se trouve. <br/>
                Pour que l’illusion soit intacte, <span class="font-black">il faut que l’élément cliquable se superpose parfaitement à l’image d’origine</span>, ce qu’on peut faire en utilisant PhotoShop ou des outils en ligne de détourage d’image.
                <br/>Il faut également gérer des variables globales (incrémenter et décrémenter) pour empêcher qu’une même fenêtre puisse s’ouvrir plusieurs fois.
                `,
                colorHexa:'#10b981',
            },
            {

                question:"Du point de vue de l’algo, qu’est-ce qui a été intéressant à faire ?",
                reponse:`
                Le choix dans le mode d’entrainement. Lorsque l’élément est cliqué, il occupe la place du centre. Ce n’est que lorsqu’un autre choix est fait qu’il retourne à sa place d’origine. Il fallait donc considérer uniquement la permutation entre l’élément entrant (celui qui occupe la place du centre) et l’élément sortant (celui qui retrouve sa place d’origine) pour éviter les autres combinaisons sans intérêt.
                `,
                colorHexa:'#059669',
            }
        ]
    },
    {
        id:'4',
        name: 'Zoohoho',
        color:'text-red-600',
        
        qr:[
            {
                question:'Dans quel contexte as-tu été amené à développer cette application ?',
                reponse:
                `
                Ce projet a donné lieu à un mémoire soutenu pour l’obtention de mon <span class="font-black">titre pro de développeur web et web mobile</span>. 
                <br/>Après neuf mois de formation (à distance, Covid oblige…) dans laquelle nous avions fait essentiellement du vanilla (pour poser les bases et découvrir les langages), 
                j’y ai vu l’occasion de découvrir un framework et de <span class="font-black">me familiariser avec le design pattern MVC (Modèle-Vue-Contrôleur)</span>.
                `,
                colorHexa:'#F9EBEA',

            },
            {
                question:'Pourquoi as-tu choisi Laravel ?',
                reponse:
                `
                Pendant la formation, nous avions abordé le design pattern MVC en mode from scratch, <span class="font-black">la problématique de l’organisation/l’architecture du code </span>étant arrivée naturellement au fil des petits projets de groupe. 
                <br/>L’intérêt de suivre un design pattern n’était donc plus à démontrer, et Laravel avait de nombreux avantages : déjà, il s’agissait de travailler concrètement avec un framework MVC, ensuite la robustesse en 
                matière de sécurité de Laravel était réputée (protection contre les attaques XSS/CSRF, gestion de hachage de mot de passe sécurisé), et <span class="font-black">c’était également une bonne occasion de « faire du SQL autrement »</span>, 
                c’est-à-dire en utilisant Eloquent, l’ORM de Laravel. 
                <br/>Par ailleurs <span class="font-black">la documentation </span>était agréable à lire et <span class="font-black">la communauté </span>de développeurs très active. 
                <br/>Je me souviens également, sur le site du framework 
                (la version 8) cette phrase qui avait achevé de me convaincre « Laravel le framework qui rend heureux ».
                
                `,
                colorHexa:'#FDEDEC',

            },
            {
                question:'En quoi consiste cette application ? Quelles sont ses fonctionnalités ?',
                reponse:
                `
                Ce projet a pour vocation de <span class="font-black">faire connaître</span> un parc zoologique et de <span class="font-black">collecter les avis des visiteurs.</span> 
                <br/>Dans la conception de mon site, <span class="font-black">seuls les utilisateurs ayant déjà visité le zoo peuvent laisser un commentaire </span>(on peut imaginer un code unique lié 
                à l’achat d’un ticket d’entrée permettant l’activation d’un compte visiteur). 
                <br/>L’utilisateur connecté peut alors laisser un commentaire, mais aussi le modifier. 
                L’objectif était donc de présenter le parc, de <span class="font-black">le situer géographiquement via l’API googlemap</span>, de présenter le personnel, les animaux, <span class="font-black">et d’enregistrer en base 
                de données </span>les commentaires laissés par les visiteurs du zoo.
                
                `,
                colorHexa:'#FAE5D3',
            }
        ]
    },
    {
        id:'8',
        name:'unit test',
        color:'text-gray-300',
        
        qr:[
            {
                question:'Tu as écris des tests unitaires pour l’application de la Tvet Academy. Peux tu nous parler de la techno de cette application ?',
                reponse: 
                `
                TVET Academy est une plate-forme d'apprentissage en ligne pour la formation professionnelle et technique (TVET) en Afrique. TVET Academy a été créée par l'Organisation internationale du travail (OIT) en partenariat avec l'Union africaine 
                et d'autres partenaires, dans le but de fournir des cours en ligne de haute qualité pour les travailleurs, les employeurs et les formateurs dans les pays africains.
                <br/>Pour favoriser et développer l’apprentissage, les utilisateurs peuvent télécharger <span class="font-black">l’application TVET Academy disponible sur android et windoxs </span>. 
                <br/>Il s’agit d’une application développée avec <span class="font-black">Cordova, un framework open-source pour le développement 
                d'applications mobiles hybrides</span>. 
                <br/>Cordova permet aux développeurs de créer des applications mobiles pour plusieurs plates-formes, telles qu'Android, iOS, Windows, etc., <span class="font-black">en utilisant des technologies web courantes telles que HTML, CSS et JavaScript.</span>
                `,
                colorHexa:'#d1d5db',
            },
            {
                question:'Quelles sont les problématiques que ce projet t’a permis d’aborder ?',
                reponse:
                `
                Une des problématiques majeures durant cette mission était de prendre en compte le fait que <span class="font-black">les utilisateurs de l’application ne disposaient pas (le plus souvent) d’un smartphone « avancé », ni d’une connexion 3G </span>. 
                <br/>Il fallait donc écrire les tests et les exécuter <span class="font-black">sur différents niveaux d’API Android </span>afin de voir comment l’application se comporte sur des smartphones qui embarquent de vieille version d’Android.
                <br/>Ce qui était challengeant c’était aussi d’<span class="font-black">accepter de ne pas chercher à comprendre tout le code </span>, mais de cibler les éléments (les boutons entre autres) et de trouver les bonnes méthodes compatibles avec Cordova pour 
                <span class="font-black">simuler les actions de l’utilisateur </span> (le scroll, le maintien du doigt sur une zone de l’écran etc) dans les scripts de tests unitaires.
                `,
                colorHexa:'#d1d5db',
            },
            {
                question:'Quelle a été ton approche, ta méthodologie pour remplir cette mission ?',
                reponse:
                `
                Dans un premier temps, il a fallu comprendre dans sa globalité comment a été codée l’application, repérer les principales fonctionnalités, <span class="font-black">relever les différents chemins pour accéder aux différents contenu </span>
                (l’objectif étant de tester un maximum de fonctionnalité), les éléments clickables et toutes les possibilités de l’application. 
                <br/>Ensuite, j’ai fait un <span class="font-black">comparatif des différents frameworks de tests unitaires compatibles avec Cordova </span> (Le choix s'est porté sur Mocha).
                <br/>J’ai été confronté à de très nombreuses problématiques tout au long de ce stage, notamment des <span class="font-black">problèmes de compatibilité </span>; par exemple sur certains niveaux d’API, le xpath ne fonctionnait pas, et pour autant 
                il fallait tester l’application sur un grand nombre de devices.
                <br/>Lorsque je rencontrais un problème, un de mes premiers reflexe était de <span class="font-black">recréer le contexte dans un environnement simplifié</span>. Par exemple, lorsqu’il a fallu reconstruire l’application de la TVET, j’ai commencé 
                par fabriquer un simple jeu de mémoire en JS et de voir comment en faire une application Cordova. Ça m’a permis de me rendre compte des problématiques qu’on peut rencontrer et comment les résoudre dans un contexte simplifié, 
                pour ensuite être en mesure de savoir quoi faire dans un contexte plus complexe.
                <br/>Pour l’organisation, car nous étions plusieurs développeurs, j’ai créé un serveur discord que j’ai structuré en fonction des thèmes afin qu’on puisse, dans ce contexte du Covid <span class="font-black"> communiquer efficacement et de manière organisée </span>.
                `,
                colorHexa:'#d1d5db',
            }
        ]
    },
    {
        id:'6',
        name:'Imhotep',
        color:'text-amber-300',
        colorHexa:'#fcd34d',
        qr:[
            {
                question:'Sur la base de quel constat est née cette application ?',
                reponse:
                `
                Cette application a pour vocation de <span class="font-black">remplacer une procédure fastidieuse</span> réalisée par l’agence comptable pour le suivi des immobilisations. 
                <br/>En effet, il faut utiliser <span class="font-black">plusieurs requêtes (5 requêtes différentes) qui ne viennent pas de la même application</span>. 
                Cette étape prend déjà plusieurs minutes. Ensuite il faut traiter manuellement sur Excel les données, et tenir un suivi manuel des données de l’application Corossol 
                <span class="font-black">qui n’est plus maintenue par Cocktail (l'éditeur du SI)</span>. Ces données de Corossol concernent les immobilisations d’avant 2017 (en 2017, le public est passé à la comptabilité GBCP, 
                qui est un nouveau mode de fonctionnement de la dépense publique). 
                <br/>Corossol <span class="font-black">fournit donc des données figées qui peuvent pourtant être amenées à évoluer</span> (sortie d’inventaire par exemple). 
                Ensuite il faut faire certains tris, faire plusieurs tableaux croisés dynamiques successifs, faire un mapping en fonction des anciens et des nouveaux comptes utilisés avant d’obtenir 
                le résultat qu’on veut : le montant de la dotation et de la reprise de l’année en cours.
                `,
                colorHexa:'#fcd34d',

            },
            {
                question:'A quoi sert cette application ? Quelle sont ses fonctionnalités ?',
                reponse:
                `
                Mon application Imhotep permet de <span class="font-black">passer les requêtes http pour récupérer les données</span>, qui sont converties en dataframe Pandas.
                <br/>Je peux alors <span class="font-black">automatiser tout ce travail de filtre, de tri, 
                d’agrégation de données et générer un fichier Excel final </span>constitués des données des requêtes, d’onglet avec des TCD « vivants » (car avec Pandas on ne peut faire que des TCD plats) et obtenir nos résultats sur un simple clic. 
                <br/>L’autre fonctionnalité d’Imhotep permet de <span class="font-black">redonner vie aux données </span> de Corossol. J’ai sauvegardé le dataset initial dans une base de données couchDB. Ensuite, <span class="font-black">via l’API, l’utilisateur peut, via l’interface faire les opérations 
                de mise à jour des données d’inventaire </span>: modifier un montant, une année, ou encore supprimer un numéro d’inventaire dans le cas d’une sortie d’inventaire.
                <br/>Enfin, Imhotep <span class="font-black">permet également d’obtenir en un seul clic toutes les requêtes dont on a besoin pour suivre les immobilisations</span>, en un seul fichier Excel (la requête fullpackage). 
                <br/>Mon application répond donc à ces différentes <span class="font-black">problématiques métiers.</span>
                `,
                colorHexa:'#fbbf24',

            },
            {
                question:'Est-ce que tu as eu recours à un « hack » (une astuce permettant de faire quelque chose qui n’est pas prévu par Tkinter) dont tu aimerais parler ?',
                reponse:
                `
                Habituellement, on utilise des boutons radio pour proposer à l’utilisateur de faire un choix exclusif (une option et pas l’autre). 
                Tkinter propose donc le widget Radiobutton qui fait cela tout à fait bien. Toutefois, plutôt que d’utiliser le sempiternel bouton radio, j’ai préféré fabriquer par moi-même le visuel permettant de choisir 
                entre deux possibilités (ici les 2 possibilités étaient : <span class="font-black">obtenir un fichier avec des TCD plats fabriqués par Pandas, ou bien des TCD modifiables, fabriqués via le module win32</span>). 
                <br/>Il fallait donc que lorsque l’utilisateur clique sur l’image de l’ankh, l’autre symbole s’éteigne, et inversement. En fonction du click, la préférence est enregistrée dans la base de données locale de l’utilisateur, 
                de sorte que son choix ne soit pas perdu lorsqu’il quittera l’application.
                Pour faire cela, j’ai fait 2 images pour chaque choix : la version « allumée » de l’image et la version éteinte. Je définis ensuite une variable (qu’on appelle textvariable) dans un widget Label via un setter. 
                Les widgets Labels sont le plus souvent utilisé comme widget de texte, <span class="font-black">mais on peut également y mettre une image en plus du texte (sans que celui-ci ne s’affiche)</span>. Je lie chacun des deux labels à l’évènement « je clique sur le label » 
                qui se traduit de cette manière avec Tkinter : variableLabel.bind("< Button-1 >", fonctionAdéclencher), et dans cette fonction qui se déclenche je récupère la valeur de la propriété text du widget avec event.widget["text"]. 
                <br/>Ensuite <span class="font-black">en fonction de la valeur de cette propriété, j’affiche l’image allumée ou éteinte </span>(et donc l’image éteinte/allumée de l’autre choix). Si et seulement si le choix est différent de celui connu dans la base de données, le 
                nouveau choix est enregistré. 
                <br/>Cette astuce m'a permis de personnaliser l’interface, d’aller plus loin que ce que propose de base Tkinter et donc de <span class="font-black">faire quelque chose de plus original</span>.
                <br/>Autre petite conception :  <span class="font-black">la console de l’application qui permet, en plus du loader, d’être informé des différentes étapes suivies par le script </span>. J’ai utilisé un widget ScrolledText dans lequel j’insère le texte en fonction de 
                l’avancement du script, <span class="font-black">de manière asynchrone (sans quoi le texte n’apparaitrait pas progressivement mais en un bloc à la fin de processus, ce qui n’aurait pas d’intérêt)</span>. 
                <br/>Cette fonction d’ajout de texte dans la console se fait 
                périodiquement: after() <span class="font-black">permet à la fonction de s’enclencher toutes les 500 millisecondes</span>. Ensuite, selon la valeur d’un booléen on poursuit l’actualisation du texte dans la console, ou on l’arrête (lorsque le booléen vaut False, 
                after_cancel() se déclenche mettant fin à l’exécution de la fonction).
                `,
                colorHexa:'#f59e0b',
            }
        ]
    },
    {
        id:'3',
        name:'Biographe',
        color:'text-blue-500',
        colorHexa:'#3b82f6',
        qr:[
            {
                question:'Quel a été le cahier des charges de cette application ?',
                reponse:
                `
                Pour donner de la visibilité à son activité, mon client devait pouvoir se faire comprendre, proposer différentes prestations, expliquer pour chacune d’entre elles ce qu’elle inclut et n’inclut pas. Il devait aussi pouvoir mettre en valeur la qualité et le style de son écriture ; c’est l’intérêt de la section « blog » dans laquelle il peut faire 
                connaître ses écrits (des nouvelles). Sur cette dernière section, il peut être entièrement autonome grâce à un control panel que j'ai codé avec React. <br/>
                Enfin, il doit être joignable (j'ai utilisé l'API mailjs pour l’envoi de mail) et on doit pouvoir le situer géographiquement (API googleMap). 
                `,
                colorHexa:'#dbeafe',
            },
            {
                question:'Pourquoi avoir choisi React ?',
                reponse:
                `
                J'ai choisi React pour plusieurs raisons stratégiques et techniques. Tout d'abord, React se distingue par sa gestion efficace des cycles de vie des composants, notamment à travers les hooks comme useEffect, 
                qui permettent de gérer des opérations cruciales comme les requêtes API ou le chargement de contenu lourd, en garantissant un rendu optimisé. La flexibilité de React permet de développer des interfaces 
                utilisateur réactives et performantes, tout en offrant une expérience fluide grâce à l'intégration de React Router DOM, qui gère les transitions entre les pages sans rechargement complet. Enfin, en utilisant React, 
                j'ai voulu approfondir ma maîtrise des concepts modernes de développement front-end, notamment son approche centrée sur les composants, qui diffère des architectures traditionnelles type MVC. 
                `,
                colorHexa:'#bfdbfe',
            },
            {
                question:'Comment est-ce que tu gère le responsive ?',
                reponse:
                `
                <span class="font-black">TailwindCSS est dans son approche « mobile first »</span>. Concrètement dans le code, on définit les classes en premier lieu pour les vues mobiles. <br/>
                Ensuite j’adapte le style selon les autres tailles d’écran (sm pour 640px et plus, md pour 768px et plus, lg pour 1024px et plus, 
                xl pour 1280px et plus, 2xl pour 1536px et plus).<br/>
                Pour la méthode de mise en page CSS, j’utilise principalement <span class="font-black">les Flexbox</span>, qui permettent d’avoir un contrôle précis sur l’alignement, la taille et l’ordre des éléments et de créer ainsi des rendus qui s’adapte à différentes tailles d’écran.<br/>
                Les classes de Tailwindcss permettent 
                d’utiliser flexblox avec relativement peu de syntaxe. 
                <br/>Conjointement à cela, j’utilise <span class="font-black">le logiciel open-source ResponsivelyApp</span> pour visualiser le rendu / tester la responsivité sur différents devices, ce qui me permet d’adapter mon code en conséquence.

                `,
                colorHexa:'#60a5fa',
            }
        ]
    },
    {
        id:'7',
        name:'CatchaData',
        color:'text-cyan-500',
        
        qr:[
            {
                question:'Quelles sont les fonctionnalités de cette application de bureau ?',
                reponse:
                `
                CatchaData permet de <span class="font-black">récupérer les données du SI via son API</span>. L’éditeur du SI c’est l’Association Cocktail dont les applications sont utilisées par de nombreux établissements d’enseignement supérieur en France. 
                <br/>J’ai créé de nombreuses requêtes dans cette application, notamment une qui produit un fichier Excel constitué de 72 colonnes, résultant de <span class="font-black">jointures successives liées aux différentes sources de données qui la compose</span>. 
                <br/>Cette application permet également de <span class="font-black">suivre des indicateurs clé de gestion </span>dont les résultats sont stockés localement dans une base de données SQL.
                <br/>Enfin, cette application permet de parcourir aléatoirement les opérations de recherche en vue d’obtenir les dernières informations concernant le suivi des encaissements. Ces informations sont saisies en amont dans une autre application, 
                et les données sont enregistrées dans <span class="font-black">Apache CouchDB</span>, une base de données NoSQL. <span class="font-black">Là encore, J’utilise une API pour les récupérer.</span> 
                `,
                colorHexa:'#ccffff',
            },
            {
                question:'Comment les données sont-elles stockées ?',
                reponse:
                `
                Les identifiants LDAP (mot de passe et utilisateur) sont <span class="font-black">stockés localement dans l’ordinateur de l’utilisateur</span>. 
                <br/>Ces données sont cryptées : Fernet génère une clé unique, et <span class="font-black">c’est cette clé unique et seulement cette clé </span>qui peut décrypter les données LDAP d’un utilisateur donné.
                <br/>Quant aux données provenant de l’API, elles sont enregistrées localement (dans le disque dur de l’ordinateur de l’utilisateur) <span class="font-black">dans des fichiers JSON</span>, dans différents dossiers en fonction des « thématiques » (budget, recettes, dépenses, documents etc.). 
                <br/>Ces fichiers sont ensuite mis à jour lorsque l’utilisateur utilise la fonctionnalité de requête ou de calculs d’indicateurs.                
                `,
                colorHexa:'#b3ffff',
            },
            {
                question:'Un challenge technique dans le développement de cette application ?',
                reponse:
                `
                Le serveur renvoi des <span class="font-black">pages de données</span> car la plupart des requêtes renvoient des centaines de milliers d’enregistrement (il faut prendre en compte les données de l’exercice actuel mais aussi toutes les données des exercices antérieurs) 
                que le serveur <span class="font-black">ne peut pas restituer en une seule fois</span>. 
                Les données des exercices antérieurs ne changeant plus, il faut pouvoir les récupérer une seule fois. 
                <br/>Pour obtenir les données de l’exercice actuel, il faut donc parcourir les pages de données disponibles (on envoi en paramètre de la requête http, un paramètre propre au numéro de page) et <span class="font-black">mettre à jour uniquement la dernière page pour 
                avoir les données les plus à jour.</span>
                <br/>Toutefois, des écritures d’inventaires peuvent être passées (généralement en début d’année) : elles concernent l’année N-1. Il faut donc <span class="font-black">intégrer la possibilité de déclencher une mise à jour sur la dernière page de donnée de N-1 </span>et le 
                faire uniquement lorsque cela est nécessaire pour éviter de passer des requêtes inutilement.
                <br/>L’algo de mise à jour des données était donc plus complexe que prévu et l’enjeux était primordial car <span class="font-black">ce mécanisme de mise à jour est le cœur de l’application.</span>
                <br/>D’autres points un peu challengeant, côté front, étaient liés au fonctionnement de Tkinter. Tkinter est une librairie et non un framework, <span class="font-black">autrement dit il ne propose pas une architecture de code </span>; il faut donc s’organiser tout seul. 
                <br/>Par ailleurs Tkinter s’exécute dans une boucle (le fameux mainloop() ), ce qui implique d’<span class="font-black">être particulièrement attentif quand on utilise des superglobales</span> par exemple : il faut penser à réinitialiser les variables aux bons endroits pour éviter des anomalies et des erreurs dans les résultats.
                `,
                colorHexa:'#33ffff',
            }
            
        ]
    },
    {
        id:'2',
        name:'Psycho',
        color:'text-cyan-500',
        qr:[
            {
                question:
                `
                Quel est l’intérêt de ce projet ?
                `,
                reponse:
                `
                <div>
                    <span>Pour l'utilisateur cette application permet de découvrir les quelques 190 biais cognitifs grâce à l'apport théorique de Buster Benson
                    (Cognitive Bias Codex), mais surtout d'apprendre à les repérer dans les situations de la vie de tous les jours. La connaissance des biais
                    cognitifs permet de limiter un grand nombre d'erreur de jugement conduisant à une mauvaise prise de décision.</span><br/>                    
                    </span>
                </div>
                `,
                colorHexa:'#e1c4ff'
                
            },

            {
                question:
                `
                Comment tu sécurises l'accès au serveur ?
                
                `,
                reponse:
                `
                La communication avec le serveur VPS se fait via SSH (Secure Shell), un protocole sécurisé permettant d’établir une connexion cryptée entre un client et un serveur distant. L’authentification SSH passe par l’utilisation d’une paire de clés cryptographiques.
Le principe est le suivant : lors de la création de la paire de clés SSH, une clé privée (stockée dans l’ordinateur de l’utilisateur) et une clé publique (partagée avec le serveur distant) sont générées. Lorsqu'une connexion SSH est établie, les clés sont échangées et vérifiées pour garantir l'identité de l'utilisateur.
                
                `,
                colorHexa:'#ce9dff'
            },
            {
                question:
                `
                Pourquoi Nginx ?
                
                `,
                reponse:
                `
                <div>
                    Principalement parce que Nginx est conçu pour fonctionner efficacement avec une faible 
                    consommation de ressources système (notamment en termes de mémoire et de processeur). 
                    Nginx me semblait donc être un choix adapté pour mon serveur VPS, qui est assez limité en termes de ressources 
                    (j’ai souscrit à l’offre la moins onéreuse, les specs techniques sont basiques).
                    D’autres arguments (bien qu’ils soient relativement secondaires dans le contexte de mon projet) 
                    permettent d’orienter le choix vers Nginx :  ses performances élevées notamment pour les sites à fort trafic 
                    et les applications nécessitant une haute performance, et sa capacité de traitement des requêtes 
                    concurrentes : Nginx utilise une architecture événementielle et non bloquante qui lui permet de 
                    traiter efficacement les requêtes concurrentes sans créer de nouveaux processus ou 
                    threads pour chaque connexion.
                </div>
                `,
                colorHexa:'#c489ff'
            }
        ]
    },
    {
        id:'1',
        name:'FedoraShop',
        color:'text-cyan-500',
        qr:[
            {
                question:
                `
                Comment sont gérées les requêtes concurrentes ?
                `,
                reponse:
                `
                <div>
                    On parle de requêtes concurrentes lorsque plusieurs utilisateurs ou processus tentent de modifier simultanément la même ressource 
                    (comme les quantités en stock). Par exemple, un scénario courant pourrait être celui où deux utilisateurs essaient d'acheter 
                    la dernière unité en stock d'un produit au même moment. Sans un mécanisme approprié, il pourrait arriver que cette dernière unité 
                    soit vendue à la fois aux deux utilisateurs, créant ainsi une incohérence dans le système. 
                    Pour gérer correctement ce type de scénario, j’ai mis en place un système d’optimistic locking. Ce mécanisme permet de s'assurer que 
                    lorsqu’un utilisateur tente de mettre à jour le stock, le système vérifie d’abord si le stock n’a pas été modifié par une autre transaction 
                    en parallèle. Si une autre mise à jour a déjà eu lieu, la transaction échoue, et l’application peut alors décider de réessayer et/ou d’informer 
                    l’utilisateur, garantissant ainsi que la dernière unité ne soit pas vendue deux fois.
                </div>

                <div>
                    Techniquement, mon entité Stock contient un champ annoté avec l’annotation JPA @Version. À chaque modification d’un enregistrement de stock, 
                    la valeur du champ version est automatiquement incrémentée. Lorsqu'une requête tente de mettre à jour un stock, Spring vérifie que la version 
                    envoyée correspond à la version actuelle en base. Si une autre transaction a déjà modifié cet enregistrement, la version aura changé et une 
                    exception OptimisticLockingFailureException sera levée.
                </div>

                <div>
                    Pour gérer ces situations, un système de retry est mis en place : si une requête échoue à cause d’un conflit, l’application réessaye plusieurs 
                    fois de réaliser l’opération, en récupérant les dernières données de la base pour ajuster la mise à jour.
                </div>

                <div>
                    Conjointement, l’ensemble des opérations effectuées au sein de la méthode d’enregistrement d’une commande s’exécutent dans une transaction 
                    (annotation JPA @Transactional), ce qui signifie que toutes les modifications dans la base de données sont appliquées de manière atomique : 
                    si une exception survient, l’ensemble des modifications sera annulé (rollback).
                </div>

                `,
                colorHexa:'#FEA347'

            },
            {
                question:
                `
                Comment as-tu implémenté la mise à jour en temps réel des stocks côté client ?
                `,
                reponse:
                `
                <div>
                    Pour afficher les données de stock en temps réel sur l'interface utilisateur, j'ai mis en place un système basé sur WebSocket entre le back-end et 
                    le front-end. Côté back-end, j'utilise Spring Boot pour gérer la connexion WebSocket via un endpoint sécurisé wss. out comme HTTPS chiffre les 
                    communications pour garantir la sécurité des échanges entre le client et le serveur, WSS utilise le protocole SSL/TLS pour chiffrer les données 
                    transmises via les connexions WebSocket.
                </div>

                <div>
                    Ce canal WebSocket permet de transmettre instantanément les mises à jour de stock aux clients front-end. Côté front-end, avec Angular, j'ai créé 
                    un service dédié (StockWebSocketService). Ce service gère la connexion WebSocket et permet de recevoir en temps réel les mises à jour de stock. 
                </div>

                <div>
                    Grâce aux Observables d'Angular (fournis par la librairie RxJS), je peux alors souscrire aux messages envoyés via WebSocket et réagir à chaque 
                    nouvelle donnée de stock. Cela permet à l'application de mettre automatiquement à jour l'affichage des quantités en stock des produits dès qu'un 
                    changement est détecté.
                </div>

                <div>
                    Concrètement, le service StockWebSocketService établit la connexion à l'endpoint WebSocket et expose un observable auquel mes composants Angular 
                    peuvent s’abonner. Dès qu'une mise à jour de stock est envoyée depuis le serveur, cet observable émet une nouvelle valeur que l’interface utilisateur 
                    peut afficher immédiatement. De cette manière l’utilisateur voit les stocks en temps réel.
                </div>

                `,
                colorHexa:'#FEA347'

            },
            {
                question:
                `
                Comment tu sécurises les endpoints de ton API ?
                `,
                reponse:
                `
                <div>
                Pour sécuriser les endpoints de mon API, j'ai mis en place deux niveaux de sécurité : l'authentification et l'autorisation. L'authentification est réalisée via des tokens JWT (JSON Web Token), qui sont requis pour accéder aux endpoints protégés. Ces tokens vérifient l'identité de l'utilisateur et assurent l'intégrité des données transmises. Concernant l'autorisation, les endpoints sensibles, notamment ceux réservés aux administrateurs, ne sont accessibles qu'aux utilisateurs possédant le rôle approprié. Le contrôle d'accès est géré via des rôles définis, et les tokens sont également utilisés pour vérifier ces rôles. De plus, les tokens ont une durée de vie limitée pour minimiser les risques de sécurité.

                </div>
                `,
                colorHexa:'#EF9B0F'
                
            },
            {
                question:
                `
                Comment tu sécurises le process de paiement d'une commande ?
                
                `,
                reponse:
                `
                Le processus de paiement est sécurisé en plusieurs étapes clés. Après authentification via le token JWT, le serveur vérifie 
                l'intégrité des données envoyées par le client, comme les prix des produits et les quantités, afin de prévenir toute manipulation 
                des données côté client. Ces vérifications sont essentielles pour garantir que seules des commandes valides sont traitées. 
                La validation finale du paiement est effectuée par Stripe, un processeur de paiement qui génère un "payment intent ID" unique pour chaque transaction. 
                Ce paiement est ensuite lié à une commande spécifique, assurant qu'aucun ID n'est réutilisé. Aucune information bancaire n'est 
                stockée sur le serveur, seulement les "payment intent ID" sont enregistrés pour référence. Toutes les communications sont chiffrées 
                via le protocole TLS (Transport Layer Security) pour garantir la confidentialité des informations échangées.


                
                `,
                colorHexa:'#E67E30'
            }
        ]
    }




]

